import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { Md5 } from 'node_modules/ts-md5';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../api/api.service';
import { LangService } from '../lang/lang.service';
import { IdiomaModel } from '../model/idioma.model';
import { environment } from 'src/environments/environment';
import { EmpresaModel } from '../model/empresa.model';
import { AplicacionModel } from '../model/aplicacion.model';
import { DateUtils } from '../utils/date-utils';
import { UsuarioModel } from '../model/usuario.model';
import { sha256 } from 'js-sha256';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('tplCodigo', { read: TemplateRef }) tplCodigo: TemplateRef<any>;
  @ViewChild('tplCodigoDF', { read: TemplateRef }) tplCodigoDF: TemplateRef<any>;
  @ViewChild('tplPassword', { read: TemplateRef }) tplPassword: TemplateRef<any>;
  @ViewChild('tplNewUser', { read: TemplateRef }) tplNewUser: TemplateRef<any>;

  public anno = new Date().getFullYear();
  public validateForm!: FormGroup;
  public passwordVisible = false;
  public newPasswVisible = false;
  public newPasswVisible2 = false;
  public user?: string;
  public password?: string;
  public errMessage: string;
  public errVisible = false;
  public infoMessage: string;
  public infoMessage2: string;
  public infoVisible = false;
  public codigo: string;
  public newPassword: string;
  public newPassword2: string;
  public idiomas: IdiomaModel[] = [];
  public newEmail: string = "";
  public newName: string = "";
  public newIdioma = '1';
  public environment = environment;
  public empresa: EmpresaModel = new EmpresaModel();
  public aplicacion: AplicacionModel = new AplicacionModel();
  public qrElementType;
  public qrCorrectionLevel;
  public qrValue;
  public passwordUsuLog = '';

  constructor(
    private apiService: ApiService,
    public langService: LangService,
    private loginService: LoginService,
    private router: Router,
    private fb: FormBuilder,
    private modal: NzModalService) {
  }

  async ngOnInit(): Promise<void> {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
    // Recupero el idioma que me puede venir como parámetro en la Url
    const paramLangId = new URLSearchParams(window.location.search).get('i');
    // Si ya hay un usuario validado pasamos a la página principal
    const usuario = this.loginService.getActiveUser();
    if (usuario !== null && usuario.Accesos !== null) {
      this.router.navigateByUrl('/main');
    } else {
      if (await this.apiService.getToken()) {
        this.idiomas = await this.langService.getIdiomas();
        // Si me viene el idioma por parámetros lo cargo, de lo contrario cargo el último que se usó
        if (paramLangId) {
          this.langService.getText(Number.parseInt(paramLangId));
          // Actualizo el idioma para creación de nuevos ususarios
          this.newIdioma = '' + paramLangId;
        } else {
          this.langService.getText(this.loginService.getLastLang());
          // Actualizo el idioma para creación de nuevos ususarios
          this.newIdioma = '' + this.loginService.getLastLang();
        }
        // Si se ha configurado una empresa y aplicación las cargo
        if (environment.empresaId > 0) {
          this.empresa = await this.loginService.getEmpresa(environment.empresaId);
          this.aplicacion = await this.loginService.getAplicacion(environment.aplicacionId);
        }
      }
    }
  }

  async submitForm(): Promise<void> {
    try {
      let lastError = null;
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
      await this.apiService.getToken();
      // Genero el hash SHA256
      this.passwordUsuLog = sha256(this.validateForm.value.password);
      let usuario = await this.loginService.validateUser(this.validateForm.value.userName, this.passwordUsuLog);
      if (!usuario) {
        lastError = LoginService.getLastLoginError();
        // Si no lo ecuentro lo busco con la clave en MD5
        const md5 = new Md5();
        this.passwordUsuLog = md5.appendStr(this.validateForm.value.password).end().toString();
        usuario = await this.loginService.validateUser(this.validateForm.value.userName, this.passwordUsuLog);
      }
      if (usuario !== null) {
        if (usuario.Accesos) {
          const now = new Date();
          // Si se ha configurado doble factor de autenticación compruebo si han pasado más de 24 horas
          if (usuario.DobleFactor && now.getTime() - usuario.FechaDF.getTime() > (24 * 3600 * 1000)) {

            // Esto es para generar un código QR
            // this.generaQR();

            const result = await this.loginService.generateDFCode(this.validateForm.value.userName);
            if (result) {
              this.modal.info({
                nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
                nzContent: this.tplCodigoDF,
                nzCentered: true,
                nzCancelText: this.langService.translate(this.langService.CANCELAR),
                nzOkText: this.langService.translate(this.langService.ACEPTAR),
                nzOnOk: async () => {
                  const res = await this.loginService.validateDFCode(this.validateForm.value.userName, Number.parseInt(this.codigo));
                  if (res) {
                    this.accede(usuario);
                  } else {
                    this.showError(this.langService.translate(this.langService.FALLO_CODIGO_PASSW), 3000);
                    return false;
                  }
                }
              });
            } else {
              this.showError(this.langService.translate(this.langService.NO_CONEX_SERVIDOR), 3000);
            }
          } else {
            this.accede(usuario);
          }
        } else {
          this.showError(this.langService.translate(this.langService.USU_SIN_ACCESOS_APP), 3000);
        }
      } else {
        const err = LoginService.getLastLoginError();
        if (err) {
          if (lastError && lastError.errCode !== 'Error 1') {
            this.showErrorMessage(lastError);
          } else {
            this.showErrorMessage(err);
          }
          return;
        }
        this.showError(this.langService.translate(this.langService.USU_PASSW_NO_VALIDOS), 3000);
      }
    } catch (e) {
      this.showError(this.langService.translate(this.langService.NO_CONEX_SERVIDOR), 3000);
    }
  }

  showErrorMessage(error: any) {
    // Error 1; Usuario o contraseña no válidos
    // Error 2; Usuario dado de baja
    // Error 3; Usuario no validado
    // Error 4; Contraseña erronea, dispone de N intentos
    // Error 5; Usuario bloqueado durante 15 minutos tras tres intentos fallidos
    // Error 6; Usuario bloqueado, inténtelo de nuevo dentro de N minutos
    // Error 7; Usuario bloqueado tras tres meses sin actividad, póngase en contacto con su administrador
    let message = this.langService.translate(error.errCode);
    switch (error.errCode) {
      case 'Error 4':
        message = message + ' ' + this.extraeNum(error.errMessage) + ' ' + this.langService.translate('Intentos').toLowerCase();
        break;
      case 'Error 6':
        message = message + ' ' + this.extraeNum(error.errMessage) + ' ' + this.langService.translate('Minutos').toLowerCase();
        break;
    }
    this.showError(message, 3000);
  }

  extraeNum(text: string): string {
    const strArray = text.split(' ');
    for (let i = 0; i < strArray.length; i++) {
      if (Number.parseInt(strArray[i]) >= 0) {
        return strArray[i];
      }
    }
    return '0';
  }

  accede(usuario: UsuarioModel) {
    if (usuario.ControlPassword) {
      this.codigo = '';
      this.newPassword = '';
      this.newPassword2 = '';
      const now = new Date();
      let dif = now.getTime() / 1000 - usuario.FechaCambioPassword.getTime() / 1000;
      if (usuario.FechaCambioPassword.getFullYear() < 2000) { // La primera vez se pregunta si quiere cambiar
        this.modal.confirm({
          nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
          nzContent: this.langService.translate(this.langService.PRIMER_ACCESO) + '.<br>' +
            this.langService.translate(this.langService.CAMBIAR_PASSW_USU) + ' ?',
          nzCentered: true,
          nzCancelText: this.langService.translate(this.langService.CANCELAR),
          nzOkText: this.langService.translate(this.langService.ACEPTAR),
          nzOnOk: async () => {
            this.onChangePassword(usuario);
          },
          nzOnCancel: async () => {
            this.loginService.modificaPassword(this.validateForm.value.userName, this.validateForm.value.password);
            this.accedeYa(usuario);
          }
        });
      } else {
        if (dif >= (24 * 3600 * 180)) { // Obligado cambiar la contraseña cada 6 meses
          this.modal.confirm({
            nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
            nzContent: this.langService.translate(this.langService.MAS_6_MESES),
            nzCentered: true,
            nzCancelText: this.langService.translate(this.langService.CANCELAR),
            nzOkText: this.langService.translate(this.langService.ACEPTAR),
            nzOnOk: async () => {
              this.onChangePassword(usuario);
            }
          });
        } else {
          if (dif >= (24 * 3600 * 150)) { // A los 5 meses se pregunta siempre si quiere cambiar la contraseña
            this.modal.confirm({
              nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
              nzContent: this.langService.translate(this.langService.MAS_5_MESES) + '.<br>' +
                this.langService.translate(this.langService.CAMBIAR_PASSW_USU) + ' ?',
              nzCentered: true,
              nzCancelText: this.langService.translate(this.langService.CANCELAR),
              nzOkText: this.langService.translate(this.langService.ACEPTAR),
              nzOnOk: async () => {
                this.onChangePassword(usuario);
              },
              nzOnCancel: async () => {
                this.accedeYa(usuario);
              }
            });
          } else {
            this.accedeYa(usuario);
          }
        }
      }
    } else {
      this.accedeYa(usuario);
    }
  }

  accedeYa(usuario: UsuarioModel) {
    this.showInfo(this.langService.translate('Ultimo_acceso_usuario') + ' ' +
      DateUtils.formatDateTime(usuario.ValidadoFecha, true), 3000,
      this.langService.translate('Acepta_politica_acceso'));
    setTimeout(async () => {
      // Almaceno los datos del usuario y el idioma usado
      this.loginService.setLastLang(usuario.Idioma.Id);
      this.loginService.setActiveUser(usuario);
      let numAccesos = 0;
      let indexAcceso = 0;
      if (usuario.Accesos) {
        usuario.Accesos.forEach((acceso, i) => {
          if (acceso.UrlWeb && acceso.UrlWeb.length > 0 && !acceso.Virtual) {
            numAccesos++;
            indexAcceso = i;
          }
        });
      }
      // Si el usuario sólo tiene un acceso obtengo un ticket y redirijo a la app
      if (numAccesos == 1) {
        // Creo un ticket de acceso
        const ticketId = await this.loginService.newTicket(usuario.Id, usuario.Accesos[indexAcceso].Empresa.Id,
          usuario.Accesos[indexAcceso].Aplicacion.Id, usuario.Accesos[indexAcceso].Rol.Id,
          usuario.Accesos[indexAcceso].UrlApi, usuario.Accesos[indexAcceso].UrlApiIA,
          usuario.Accesos[indexAcceso].UrlApiEcoSAT);
        window.open(usuario.Accesos[indexAcceso].UrlWeb + "?ticketId=" + ticketId + '&origin=' + window.location.hostname);
      } else {
        // Redirijo a la página principal
        this.router.navigateByUrl('/main');
      }
    }, 2000);
  }

  onForgotPassword(): void {
    this.codigo = '';
    this.newPassword = '';
    this.newPassword2 = '';
    if (this.validateForm.value.userName === null || this.validateForm.value.userName.length < 1 ||
      this.validateForm.value.userName.indexOf('@') < 0) {
      this.errVisible = true;
      this.errMessage = this.langService.translate(this.langService.INTRO_USU_VALIDO);
      setTimeout(() => {
        this.errVisible = false;
      }, 3000);
    } else {
      this.modal.confirm({
        nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
        nzContent: this.langService.translate(this.langService.RECU_PASSW_USU) + '<br>' + this.validateForm.value.userName + ' ?',
        nzCentered: true,
        nzCancelText: this.langService.translate(this.langService.CANCELAR),
        nzOkText: this.langService.translate(this.langService.ACEPTAR),
        nzOnOk: async () => {
          const result = await this.loginService.recoveryPassword(this.validateForm.value.userName);
          if (result) {
            this.modal.info({
              nzTitle: '<i>' + this.langService.translate(this.langService.INFORMACION) + '</i>',
              nzContent: this.tplCodigo,
              nzCentered: true,
              nzCancelText: this.langService.translate(this.langService.CANCELAR),
              nzOkText: this.langService.translate(this.langService.ACEPTAR),
              nzOnOk: async () => {
                const res = await this.loginService.validateCode(this.validateForm.value.userName, Number.parseInt(this.codigo));
                if (res) {
                  this.modal.confirm({
                    nzTitle: '<i>' + this.langService.translate(this.langService.RECUPERACION) + '</i>',
                    nzContent: this.tplPassword,
                    nzCentered: true,
                    nzCancelText: this.langService.translate(this.langService.CANCELAR),
                    nzOkText: this.langService.translate(this.langService.ACEPTAR),
                    nzOnOk: async () => {
                      if (this.newPassword === undefined || (this.newPassword !== this.newPassword2)) {
                        this.showError(this.langService.translate(this.langService.PASSW_NO_COINCIDEN), 3000);
                        return false;
                      }
                      if (!this.isValidPassword(this.newPassword)) {
                        this.modal.info({
                          nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
                          nzContent: this.langService.translate(this.langService.PASSW_NO_VALIDO) + ' ' + this.langService.SIMBOL_PASSW,
                          nzCentered: true,
                          nzOkText: this.langService.translate(this.langService.ACEPTAR),
                          nzOnOk: async () => {
                          }
                        });
                        return false;
                      }
                      const res = await this.loginService.modificaPassword(this.validateForm.value.userName, this.newPassword);
                      if (!res) {
                        this.showError(this.langService.translate(this.langService.FALLO_CAMBIO_PASSW), 3000);
                        return false;
                      } else {
                        this.showInfo(this.langService.translate(this.langService.PASSW_ACTUALIZADO), 3000);
                      }
                    }
                  });
                } else {
                  this.showError(this.langService.translate(this.langService.FALLO_CODIGO_PASSW), 3000);
                  return false;
                }
              }
            });
          } else {
            this.showError(this.langService.translate(this.langService.FALLO_NOMBRE_USU), 3000);
          }
        }
      });
    }
  }

  async onChangePassword(usuario: UsuarioModel): Promise<void> {
    const result = await this.loginService.recoveryPassword(this.validateForm.value.userName);
    if (result) {
      this.modal.info({
        nzTitle: '<i>' + this.langService.translate(this.langService.INFORMACION) + '</i>',
        nzContent: this.tplCodigo,
        nzCentered: true,
        nzCancelText: this.langService.translate(this.langService.CANCELAR),
        nzOkText: this.langService.translate(this.langService.ACEPTAR),
        nzOnOk: async () => {
          const res = await this.loginService.validateCode(this.validateForm.value.userName, Number.parseInt(this.codigo));
          if (res) {
            this.modal.confirm({
              nzTitle: '<i>' + this.langService.translate(this.langService.CAMBIO_PASSW) + '</i>',
              nzContent: this.tplPassword,
              nzCentered: true,
              nzCancelText: this.langService.translate(this.langService.CANCELAR),
              nzOkText: this.langService.translate(this.langService.ACEPTAR),
              nzOnOk: async () => {
                if (this.newPassword === undefined || (this.newPassword !== this.newPassword2)) {
                  this.showError(this.langService.translate(this.langService.PASSW_NO_COINCIDEN), 3000);
                  return false;
                }
                // Compruebo que la contraseña sea distinta de la actual
                const passwSHA = sha256(this.newPassword);
                const md5 = new Md5();
                const passwMD5 = md5.appendStr(this.newPassword).end().toString();
                if (this.passwordUsuLog === passwMD5 || this.passwordUsuLog === passwSHA) {
                  this.errVisible = true;
                  this.errMessage = this.langService.translate(this.langService.PASSW_NO_MISMO);
                  setTimeout(() => {
                    this.errVisible = false;
                  }, 3000);
                  return false;
                }
                if (!this.isValidPassword(this.newPassword)) {
                  this.modal.info({
                    nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
                    nzContent: this.langService.translate(this.langService.PASSW_NO_VALIDO) + ' ' + this.langService.SIMBOL_PASSW,
                    nzCentered: true,
                    nzOkText: this.langService.translate(this.langService.ACEPTAR),
                    nzOnOk: async () => {
                    }
                  });
                  return false;
                }
                const res = await this.loginService.modificaPassword(this.validateForm.value.userName, this.newPassword);
                if (!res) {
                  this.showError(this.langService.translate(this.langService.FALLO_CAMBIO_PASSW), 3000);
                  return false;
                } else {
                  this.showInfo(this.langService.translate(this.langService.PASSW_ACTUALIZADO), 3000);
                  this.accedeYa(usuario);
                }
              }
            });
          } else {
            this.showError(this.langService.translate(this.langService.FALLO_CODIGO_PASSW), 3000);
            return false;
          }
        }
      });
    } else {
      this.showError(this.langService.translate(this.langService.FALLO_NOMBRE_USU), 3000);
    }
  }

  isValidPassword(passw: string) {
    let valid = true;
    passw = passw.replace(/\s/g, '');
    if (passw.length < 8 || passw.length > 25) {
      valid = false;
    }
    let i, j;
    for (i = 0; i < passw.length && passw[i] < 'a' || passw[i] > 'z'; i++);
    if (i >= passw.length) {
      valid = false;
    }
    for (i = 0; i < passw.length && passw[i] < 'A' || passw[i] > 'Z'; i++);
    if (i >= passw.length) {
      valid = false;
    }
    for (i = 0; i < passw.length && passw[i] < '0' || passw[i] > '9'; i++);
    if (i >= passw.length) {
      valid = false;
    }
    const simbol = this.langService.SIMBOL_PASSW;
    for (i = 0; i < passw.length; i++) {
      for (j = 0; j < simbol.length && passw[i] !== simbol[j]; j++);
      if (j < simbol.length) {
        break;
      }
    }
    if (i >= passw.length) {
      valid = false;
    }
    return valid;
  }

  onNewUser(): void {
    this.modal.create({
      nzTitle: '<i>' + this.langService.translate(this.langService.CREAR_USUARIO) + '</i>',
      nzContent: this.tplNewUser,
      nzCentered: true,
      nzCancelText: this.langService.translate(this.langService.CANCELAR),
      nzOkText: this.langService.translate(this.langService.ACEPTAR),
      nzOnOk: async () => {
        if (this.newEmail.indexOf('@') < 0 || this.newEmail.indexOf('.') < 0) {
          this.errVisible = true;
          this.errMessage = this.langService.translate(this.langService.INTRO_EMAIL_VALIDO);
          setTimeout(() => {
            this.errVisible = false;
          }, 3000);
          return false;
        } else {
          if (this.newName.length < 1) {
            this.errVisible = true;
            this.errMessage = this.langService.translate(this.langService.INTRO_USU_VALIDO);
            setTimeout(() => {
              this.errVisible = false;
            }, 3000);
            return false;
          } else {
            if (this.newPassword === undefined || this.newPassword.length < 8 || (this.newPassword !== this.newPassword2)) {
              this.showError(this.langService.translate(this.langService.PASSW_NO_COINCIDEN), 3000);
              return false;
            } else {
              const res = await this.loginService.newUser(this.newEmail, this.newName, this.newPassword,
                Number.parseInt(this.newIdioma), window.location.origin);
              if (!res.result) {
                if (res.error && res.error.indexOf('Ya existe un usuario con ese e-mail') > -1) {
                  this.showError(this.langService.translate(this.langService.USU_YA_EXISTE), 3000);
                } else {
                  this.showError(this.langService.translate(this.langService.FALLO_CREAR_USU), 3000);
                }
                return false;
              } else {
                this.showInfo(this.langService.translate(this.langService.REVISE_CORREO_ACTIVAR), 5000);
              }
            }
          }
        }
      }
    });
  }

  showError(mensaje: string, tiempo: number) {
    this.errVisible = true;
    this.errMessage = mensaje;
    setTimeout(() => {
      this.errVisible = false;
    }, tiempo);
  }

  showInfo(mensaje: string, tiempo: number, message2 = '') {
    this.infoVisible = true;
    this.infoMessage = mensaje;
    this.infoMessage2 = message2;
    setTimeout(() => {
      this.infoVisible = false;
    }, tiempo);
  }

  public onChangeIdioma(idioma: number) {
    // Actualizo el idioma para la creación de usuarios
    this.newIdioma = '' + idioma;
    this.langService.getText(idioma);
  }

  generaQR() {
    this.qrElementType = NgxQrcodeElementTypes.URL;
    this.qrCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    this.qrValue = 'https://www.movisat.com/';
  }

}





