import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { AccesoUsuarioModel } from 'src/app/model/acceso.usuario.model';
import { UsuarioModel } from 'src/app/model/usuario.model';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.css']
})
export class ListCardComponent implements OnInit {

  @Input() public accesos: AccesoUsuarioModel[] = [];
  @Input() public usuario: UsuarioModel;

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {

  }

  async onSelectAcceso(acceso: AccesoUsuarioModel) {
    if (acceso.UrlWeb.length > 0) {
      // Creo un ticket de acceso
      const ticketId = await this.loginService.newTicket(this.usuario.Id, acceso.Empresa.Id, acceso.Aplicacion.Id,
        acceso.Rol.Id, acceso.UrlApi, acceso.UrlApiIA, acceso.UrlApiEcoSAT);
      window.open(acceso.UrlWeb + '?ticketId=' + ticketId + '&origin=' + window.location.hostname);
    }
  }

}
