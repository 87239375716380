// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.

  // Parámetros para la creación de nuevos ususarios.
  // Si empresaId es 0 desaparece la opción de creación de usuarios
  empresaId: 0,
  aplicacionId: 0,
  rolId: 0,
  // empresaId: 4,    // RSU Ciudad Real
  // aplicacionId: 4, // EcoCompostaje
  // rolId: 10,       // Ciudadano

  // urlApi: 'https://localhost:44342',
  // urlApi: 'https://api.sso.movisat.com',
  // urlApi: 'https://api.sso.preproduccion.movisat.com',
  urlApi: 'https://api.sso.dev.movisat.com',

  userName: "movisat@np",
  password: "M0v15AT@np2021",
  grantType: 'password',
  clientId: "U32FFNX5AG39DECT",
  clientSecret: "M65Y4L658QNFKQ2PSQWX5S5XEC7WDNBVSV2UH2GRGNZGEUATQX"
}
