import { BaseModel } from "./base.model";

export class EmpresaModel extends BaseModel {
    public Id: number;
    public Nombre: string;
    public Imagen: string;
    public IdGestion: number;
    public Cartografia: string;

    public EmpresaModel(id: number, nombre: string, imagen: string, idGestion: number, cartografia: string,
        fechaAlta: Date, fechaBaja: Date, fechaModificacion: Date) {
        this.Id = id;
        this.Nombre = nombre;
        this.Imagen = imagen;
        this.IdGestion = idGestion;
        this.Cartografia = cartografia;
        this.FechaAlta = fechaAlta;
        this.FechaBaja = fechaBaja;
        this.FechaModificacion = fechaModificacion;
    }
}
