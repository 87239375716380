<div *ngIf="errVisible" style="z-index: 9999;">
    <nz-alert style="z-index: 9999;" nzType="error" nzMessage={{langService.translate(langService.ATENCION)}}
        nzDescription={{errMessage}} nzShowIcon>
    </nz-alert>
</div>

<div *ngIf="infoVisible">
    <nz-alert nzType="info" nzMessage={{langService.translate(langService.ATENCION)}} [nzDescription]="alertTemplate"
        nzShowIcon>
    </nz-alert>
    <ng-template #alertTemplate>
        {{infoMessage}}
        <hr>
        <i>{{infoMessage2}}</i>
    </ng-template>
</div>

<ng-template #tplCodigo>
    <p>{{langService.translate(langService.REVISE_CORREO)}}
    </p>
    <input type="text" nz-input placeholder={{langService.translate(langService.CODIGO)}} [(ngModel)]="codigo" />
</ng-template>

<ng-template #tplPassword>
    <p>{{langService.translate(langService.NUEVO_PASSW)}}
        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplateRecPassw">
            <input [type]="newPasswVisible ? 'text' : 'password'" nz-input [(ngModel)]="newPassword"
                placeholder={{langService.translate(langService.PASSWORD)}} />
        </nz-input-group>
        <ng-template #suffixTemplateRecPassw>
            <i nz-icon [nzType]="newPasswVisible ? 'eye' :'eye-invisible'"
                (click)="newPasswVisible = !newPasswVisible"></i>
        </ng-template>
    </p>
    <p>{{langService.translate(langService.REPITA_PASSW)}}
        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplateRecPassw2">
            <input [type]="newPasswVisible2 ? 'text' : 'password'" nz-input [(ngModel)]="newPassword2"
                placeholder={{langService.translate(langService.PASSWORD)}} />
        </nz-input-group>
        <ng-template #suffixTemplateRecPassw2>
            <i nz-icon [nzType]="newPasswVisible2 ? 'eye' :'eye-invisible'"
                (click)="newPasswVisible2 = !newPasswVisible2"></i>
        </ng-template>
    </p>
</ng-template>

<ng-template #tplNewUser>
    <p>E-Mail
        <nz-input-group nzPrefixIcon="user">
            <input [type]="'text'" nz-input [(ngModel)]="newEmail" placeholder="E-Mail" />
        </nz-input-group>
    </p>
    <p>{{langService.translate(langService.NOMBRE_USUARIO)}}
        <input [type]="'text'" nz-input [(ngModel)]="newName"
            placeholder={{langService.translate(langService.NOMBRE_USUARIO)}} />
    </p>
    <p>{{langService.translate(langService.IDIOMA)}}<br>
        <nz-select [(ngModel)]="newIdioma">
            <nz-option *ngFor="let idioma of idiomas" [nzLabel]="idioma.Nombre" [nzValue]="''+idioma.Id"></nz-option>
        </nz-select>
    </p>
    <p>{{langService.translate(langService.NUEVO_PASSW)}}
        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplateRecPassw">
            <input [type]="newPasswVisible ? 'text' : 'password'" nz-input [(ngModel)]="newPassword"
                placeholder={{langService.translate(langService.PASSWORD)}} />
        </nz-input-group>
        <ng-template #suffixTemplateRecPassw>
            <i nz-icon [nzType]="newPasswVisible ? 'eye' :'eye-invisible'"
                (click)="newPasswVisible = !newPasswVisible"></i>
        </ng-template>
    </p>
    <p>{{langService.translate(langService.REPITA_PASSW)}}
        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplateRecPassw2">
            <input [type]="newPasswVisible2 ? 'text' : 'password'" nz-input [(ngModel)]="newPassword2"
                placeholder={{langService.translate(langService.PASSWORD)}} />
        </nz-input-group>
        <ng-template #suffixTemplateRecPassw2>
            <i nz-icon [nzType]="newPasswVisible2 ? 'eye' :'eye-invisible'"
                (click)="newPasswVisible2 = !newPasswVisible2"></i>
        </ng-template>
    </p>
</ng-template>

<ng-template #tplCodigoDF>
    <p>{{langService.translate(langService.REVISE_CORREO)}}
    </p>
    <input type="text" nz-input placeholder={{langService.translate(langService.CODIGO)}} [(ngModel)]="codigo" />

    <!-- Esto es para mostrar un código QR
    <div>
        <br>
        O escanee este código para acceder
        <ngx-qrcode [elementType]="qrElementType" [errorCorrectionLevel]="qrCorrectionLevel" [value]="qrValue"
            cssClass="qrcodeshadow">
        </ngx-qrcode>
    </div> -->

</ng-template>

<div style="float: left; width: 100%; height: 96%;">

    <div style="width: 100%; height: 50px; float: left; clear: both">
        <div *ngIf="environment.empresaId>0 && aplicacion.Imagen && empresa.Imagen">
            <img class="imagenApp" src="data:image/jpg;base64,{{aplicacion.Imagen}}" />
            <img class="imagenEmpresa" src="data:image/jpg;base64,{{empresa.Imagen}}" />
        </div>
    </div>

    <div class="logos">
        <div *ngIf="environment.empresaId>0">
            <img class="imagenMovisat" src="assets/images/movisat.png">
        </div>
        <div *ngIf="environment.empresaId==0">
            <img class="imagenMyMovisat" src="assets/images/mymovisat.png">
        </div>
    </div>

    <div class="login">
        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <nz-form-item>
                <nz-form-control>{{langService.translate(langService.USUARIO)}}
                    <nz-input-group nzPrefixIcon="user">
                        <input type="text" nz-input formControlName="userName"
                            placeholder={{langService.translate(langService.USUARIO)}} />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>{{langService.translate(langService.PASSWORD)}}
                    <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                        <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                            placeholder={{langService.translate(langService.PASSWORD)}} />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                        <i nz-icon [nzType]="passwordVisible ? 'eye' :'eye-invisible'"
                            (click)="passwordVisible = !passwordVisible"></i>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <button nz-button class="login-form-button login-form-margin"
                [nzType]="'primary'">{{langService.translate(langService.ENTRAR)}}</button>
            <div nz-row class="login-form-margin">
                <div nz-col [nzSpan]="24">
                    <a class="login-form-forgot"
                        (click)="onForgotPassword()">{{langService.translate(langService.NO_RECUE_PASSW)}}</a>
                </div>
            </div>
            <div *ngIf="environment.empresaId>0">
                <div nz-row class="login-form-margin">
                    <div nz-col [nzSpan]="24">
                        <a class="login-form-forgot"
                            (click)="onNewUser()">{{langService.translate(langService.CREAR_USUARIO)}}</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div style="float: left; width: 100%;">
    <div class="footer">
        <span style="margin-right: 10px;">
            Copyright <a href="http://www.movisat.com" target="_blank">MOVISAT</a> © {{anno}}
        </span>
        <a *ngFor="let idioma of idiomas; index as i" (click)="onChangeIdioma(idioma.Id)">
            <span>{{idioma.Nombre}}</span>
            <span *ngIf="i < idiomas.length -1" style="color: black;"> | </span>
        </a>
    </div>
</div>