<nz-layout style="height: 100%;">
  <img class="logo" src="assets/images/movisat.png">
  <div class="salir">
    <a nz-dropdown [nzDropdownMenu]="menu" class="salir" style="margin-top: -40px;">
      <img width="24px" height="24px" class="salir" style="margin-left: 5px;" src="assets/images/usuario.png">
      <div class="nombreUsu" title={{usuario.Nombre}}>
        {{usuario.Nombre}}
      </div>
      <i nz-icon nzType="down"></i>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>{{langService.translate(langService.INFORMACION)}}</li>
        <li nz-menu-item (click)="onCloseSession()">{{langService.translate(langService.CERRAR_SESION)}}</li>
      </ul>
    </nz-dropdown-menu>
  </div>

  <nz-content class="outer-content" style="overflow: hidden;">
    <nz-layout class="inner-layout">
      <nz-sider nzWidth={{anchoEmpresas}} nzTheme="light" style="width: 70%;">
        <div style="color:dodgerblue; padding-left: 17px; background-color: #f0f2f5; font-size: 120%;">
          {{langService.translate(langService.PROYECTOS)}}
        </div>
        <div style="width: auto; margin-top: 3px; margin-left: 16px; position: relative; margin-right: 18px;">
          <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="small">
            <input nz-input (input)="onInput($event)" [(ngModel)]="value" (click)="click($event)"
              [placeholder]="'Buscar'" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
        <li nz-menu class="scroll-empresas">
          <ul #emp *ngFor="let empresa of results; index as i">
            <li #listEmpresas *ngIf="i==0;else other_content" nz-menu-item [attr.data-index]="i" [nzSelected]="'true'"
              (click)="onSelectEmpresa(empresa)">
              {{empresa.Nombre}}
            </li>
            <ng-template #other_content>
              <li nz-menu-item [attr.data-index]="i" (click)="onSelectEmpresa(empresa)">
                {{empresa.Nombre}}
              </li>
            </ng-template>
          </ul>
        </li>
      </nz-sider>

      <div class="content" style="width: 100%;">
        <div class="tab">
          <button class="tablinks" (click)="selectTab('Aplicaciones')"
            [class.active]="activeTab === 'Aplicaciones'">Aplicaciones</button>
          <button class="tablinks" (click)="selectTab('Ciudadano')"
            [class.active]="activeTab === 'Ciudadano'">Ciudadano</button>
          <button class="tablinks" (click)="selectTab('Apps')" [class.active]="activeTab === 'Apps'">Apps</button>
          <button class="tablinks" (click)="selectTab('Herramientas')" hidden="true"
            [class.active]="activeTab === 'Herramientas'">Ges. Interna (Gestión Interna)</button>
        </div>
        <div [ngClass]="{'tabcontent': true, 'active': activeTab === 'Aplicaciones'}" id="Aplicaciones">
          <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
        </div>
        <div [ngClass]="{'tabcontent': true, 'active': activeTab === 'Ciudadano'}" id="Ciudadano">
          <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
        </div>
        <div [ngClass]="{'tabcontent': true, 'active': activeTab === 'Apps'}" id="Apps">
          <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
        </div>
        <div [ngClass]="{'tabcontent': true, 'active': activeTab === 'Herramientas'}" id="Herramientas">
          <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
        </div>
      </div>
    </nz-layout>
  </nz-content>
  <nz-footer>
    Copyright <a href="http://www.movisat.com" target="_blank">MOVISAT</a> © {{anno}} | ver. {{verSoft}} |
    {{entorno.value}}
  </nz-footer>
</nz-layout>