import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AplicacionModel } from '../model/aplicacion.model';
import { EmpresaModel } from '../model/empresa.model';
import { UsuarioModel } from '../model/usuario.model';
import { sha256 } from 'js-sha256';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private usuarioActual: UsuarioModel = null;
  private static lastLoginError?: any;

  constructor(private http: HttpClient) {
  }

  setActiveUser(usuario: UsuarioModel) {
    this.usuarioActual = usuario;
    // Antes de almacenar los datos quito las imágenes de empresas y aplicaciones
    // para no sobrepasar el límite de datos permitido por el navegador
    const auxUsu = { ...usuario };
    if (auxUsu.Accesos) {
      auxUsu.Accesos.forEach(acceso => {
        acceso.Aplicacion.Imagen = '';
        acceso.Empresa.Imagen = '';
      });
    }
    localStorage.setItem('userSSO', JSON.stringify(auxUsu));
  }

  getActiveUser(): UsuarioModel {
    this.usuarioActual = <UsuarioModel>JSON.parse(localStorage.getItem('userSSO'));
    return this.usuarioActual;
  }

  removeActiveUser() {
    localStorage.removeItem('userSSO');
  }

  setLastLang(id: number) {
    localStorage.setItem('lastLangSSO', '' + id);
  }

  getLastLang(): number {
    const lang = localStorage.getItem('lastLangSSO');
    return lang === null ? 1 : Number.parseInt(lang);
  }

  // Valida un usuario y devuelve su información
  async validateUser(user: string, passw: string): Promise<UsuarioModel> {
    let result: UsuarioModel = null;
    // Preparo la cabecera
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json'
      })
    }
    const body = {
      UsuarioLogin: user,
      UsuarioPassword: passw
    }
    try {
      result = await this.http.post<UsuarioModel>(environment.urlApi + '/sso/usuario/valida',
        JSON.stringify(body), httpOptions).toPromise();
      if (result) {
        result.FechaAlta = new Date(result.FechaAlta);
        result.FechaBaja = new Date(result.FechaBaja);
        result.FechaModificacion = new Date(result.FechaModificacion);
        result.ValidadoFecha = new Date(result.ValidadoFecha);
        result.FechaDF = new Date(result.FechaDF);
        result.FechaCambioPassword = new Date(result.FechaCambioPassword);
      }
    } catch (e) {
      console.log(e);
      if (e.error) {
        LoginService.lastLoginError = e.error;
      }
    }
    return result;
  }

  static getLastLoginError(): any {
    if (LoginService.lastLoginError && LoginService.lastLoginError.error) {
      return {
        errCode: LoginService.lastLoginError.error.split(';')[0],
        errMessage: LoginService.lastLoginError.error.split(';')[1]
      }
    }
    if (LoginService.lastLoginError) {
      return {
        errCode: LoginService.lastLoginError.split(';')[0],
        errMessage: LoginService.lastLoginError.split(';')[1]
      }
    }
    return {
      errCode: 0,
      errMessage: ''
    };
  }

  // Invalida un usuario, cierra la sesión
  async invalidateUser(userId: number): Promise<boolean> {
    try {
      return await this.http.get<boolean>(environment.urlApi + '/sso/usuario/invalida/' + userId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // Permite recuperar la contraseña
  async recoveryPassword(user: string): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    const usuario = {
      UsuarioLogin: user
    }
    try {
      return await this.http.post<boolean>(environment.urlApi + '/sso/usuario/recuperapassw',
        JSON.stringify(usuario), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // Permite generar un código para la autenticacion de doble factor 
  async generateDFCode(user: string): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    const usuario = {
      UsuarioLogin: user
    }
    try {
      return await this.http.post<boolean>(environment.urlApi + '/sso/usuario/generadfcodigo',
        JSON.stringify(usuario), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // Permite validar un código de autenticación 
  async validateDFCode(user: string, codigo: number): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    const usuario = {
      UsuarioLogin: user,
      CodigoRecuperacion: codigo
    }
    try {
      return await this.http.post<boolean>(environment.urlApi + '/sso/usuario/validadfcodigo',
        JSON.stringify(usuario), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return false;
  }


  // Permite validar un código de recuperación de contraseña
  async validateCode(user: string, codigo: number): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    const usuario = {
      UsuarioLogin: user,
      CodigoRecuperacion: codigo
    }
    try {
      return await this.http.post<boolean>(environment.urlApi + '/sso/usuario/validacodigo',
        JSON.stringify(usuario), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // Permite modificar la contraseña de un usuario
  async modificaPassword(user: string, password: string): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    const usuario = {
      UsuarioLogin: user,
      UsuarioPassword: sha256(password)
    }
    try {
      return await this.http.post<boolean>(environment.urlApi + '/sso/usuario/modificapassw',
        JSON.stringify(usuario), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  // Crea un nuevo ticket de acceso
  async newTicket(userId: number, empresaId: number, appId: number, rolId: number,
    urlApi: string, urlApiIA: string, urlApiEcoSAT: string): Promise<string> {
    // Preparo la cabecera
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json'
      })
    }
    const body = {
      UsuarioId: userId,
      EmpresaId: empresaId,
      AplicacionId: appId,
      RolId: rolId,
      UrlApi: urlApi,
      UrlApiIA: urlApiIA,
      UrlApiEcoSAT: urlApiEcoSAT
    }
    try {
      var response = await this.http.post(environment.urlApi + '/sso/ticket',
        JSON.stringify(body), httpOptions).toPromise();
      const res = JSON.parse(JSON.stringify(response));
      if (res !== null) {
        return res.TicketId;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  // Permite crear un usuario
  async newUser(email: string, nombre: string, password: string, idioma: number, urlWeb: string):
    Promise<{ result: boolean, error: string }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    const usuario = {
      UsuarioLogin: email,
      UsuarioPassword: sha256(password),
      UsuarioNombre: nombre,
      IdiomaId: idioma,
      Urlweb: urlWeb
    }
    try {
      // Primero creo el usuario
      if (await this.http.post<boolean>(environment.urlApi + '/sso/usuario/crear',
        JSON.stringify(usuario), httpOptions).toPromise()) {
        // Creo el acceso predeterminado segun los parámetros configurados
        const acceso = {
          UsuarioLogin: email,
          EmpresaId: environment.empresaId,
          AplicacionId: environment.aplicacionId,
          RolId: environment.rolId
        }
        return {
          result: await this.http.post<boolean>(environment.urlApi + '/sso/usuario/crearacceso',
            JSON.stringify(acceso), httpOptions).toPromise(), error: ''
        };
      }
    } catch (e) {
      console.log(e);
      return { result: false, error: e.error.error };
    }
    return { result: false, error: '' };
  }

  // Recupera los datos de una empresa
  async getEmpresa(empresaId: number): Promise<EmpresaModel> {
    try {
      return await this.http.get<EmpresaModel>(environment.urlApi + '/sso/empresa/' + empresaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  // Recupera los datos de una aplicación
  async getAplicacion(appId: number): Promise<AplicacionModel> {
    try {
      return await this.http.get<AplicacionModel>(environment.urlApi + '/sso/aplicacion/' + appId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

}
