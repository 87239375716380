import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { NzLayoutModule} from 'ng-zorro-antd/layout';
import { NzSliderModule} from 'ng-zorro-antd/slider';
import { NzIconModule} from 'ng-zorro-antd/icon';
import { NzMenuModule} from 'ng-zorro-antd/menu';
import { NzCardModule} from 'ng-zorro-antd/card';
import { NzGridModule} from 'ng-zorro-antd/grid';
import { NzListModule} from 'ng-zorro-antd/list';
import { NzDropDownModule} from 'ng-zorro-antd/dropdown';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    NzLayoutModule,
    NzSliderModule,
    NzIconModule,
    NzMenuModule,
    NzCardModule,
    NzGridModule,
    NzListModule,
    NzDropDownModule,
    NzAutocompleteModule,
    NzInputModule,
    FormsModule,
    ComponentsModule
  ]
})
export class MainModule { }
