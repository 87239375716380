<nz-content style="overflow: auto;">
  <nz-list nzGrid style="height: 100%;padding: 4px;">
    <div nz-row [nzGutter]="[8,8]" style="display: flex; flex-wrap: wrap;">
      <nz-list-item nz-col *ngFor="let acceso of accesos; index as i" style=" height: 80%; box-sizing: border-box;">
        <div class="card" (click)="onSelectAcceso(acceso)">
          <img [src]="'data:image/png;base64,' + acceso.Aplicacion.Imagen" alt="Card Image" class="card-image">
          <div class="card-content">
            <span class="card-title">{{ acceso.Aplicacion.Nombre }}</span>
            <span class="card-text">{{ acceso.Rol.Nombre }}</span>
          </div>
        </div>
      </nz-list-item>
    </div>
  </nz-list>
</nz-content>