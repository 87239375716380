import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { LoginService } from '../login/login.service';
import { AccesoUsuarioModel } from '../model/acceso.usuario.model';
import { EmpresaModel } from '../model/empresa.model';
import { UsuarioModel } from '../model/usuario.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LangService } from '../lang/lang.service';

interface Entorno {
  url: string;
  value: string;
}


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class MainComponent implements OnInit {
  public anno = new Date().getFullYear();
  public usuario: UsuarioModel = new UsuarioModel();
  public empresas: EmpresaModel[] = [];
  public accesos: AccesoUsuarioModel[] = [];
  public empresaSelect: EmpresaModel = null;
  public accesoSelect: AccesoUsuarioModel = null;
  private timer = null;
  public anchoEmpresas = '25%'
  public results: EmpresaModel[] = [];
  public value: string;
  public verSoft = '240502.01';
  public entorno: Entorno = {
    url: '',
    value: ''
  };
  public activeTab: string = 'Aplicaciones';

  constructor(
    private apiService: ApiService,
    private loginService: LoginService,
    private router: Router,
    private modal: NzModalService,
    public langService: LangService) {
  }

  async ngOnInit(): Promise<void> {
    this.results = this.empresas;
    // Cuando se redimensiona la ventana del navegador
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 400) {
        this.anchoEmpresas = '60%'
      } else {
        if (window.innerWidth < 800) {
          this.anchoEmpresas = '33%';
        } else {
          this.anchoEmpresas = '25%';
        }
      }
    });

    window.dispatchEvent(new Event('resize'));

    // Cojo el usuario guardado en localstorage
    this.usuario = this.loginService.getActiveUser();
    if (this.usuario) {
      if (!this.apiService.token) {
        await this.apiService.getToken();
      }
      // Compruebo si está validado en el SSO
      this.usuario = await this.loginService.validateUser(this.usuario.Email, '');
      if (this.usuario && this.usuario.Accesos) {
        // Cargo los textos según el idioma del usuario
        this.langService.getText(this.loginService.getActiveUser().Idioma.Id);
        // Cargo las empresas y las aplicaciones del usuario
        let lastEmp = 0;
        this.usuario.Accesos.forEach(acceso => {
          if (lastEmp !== acceso.Empresa.Id && !acceso.Virtual) {
            this.empresas.push(acceso.Empresa);
            lastEmp = acceso.Empresa.Id;
          }
        });
        // Cargo los accesos a las aplicaciones
        if (this.empresas.length > 0) {
          this.empresas.sort((a, b) => (a.Nombre > b.Nombre) ? 1 : ((b.Nombre > a.Nombre) ? -1 : 0))
          this.empresaSelect = this.empresas[0];
          this.usuario.Accesos.forEach(acceso => {
            if (acceso.Empresa.Id === this.empresas[0].Id && !acceso.Aplicacion.NoWeb && !acceso.Virtual) {
              this.accesos.push(acceso);
            }
          });
          // Inicializo un temporizador que se encarga de ver si el usuario sigue validado cada minuto
          if (this.timer !== null) {
            clearInterval(this.timer);
          }
          this.timer = setInterval(async () => {
            if (!await this.apiService.isUsuarioValidado(this.usuario.Id)) {
              this.closeSession();
            }
          }, 60000);
        } else {
          this.logout();
        }
      } else {
        this.logout();
      }
    } else {
      this.router.navigateByUrl('/');
    }
    this.entorno.url = window.location.origin;
    this.moodEntorno();

  }

  // Muestra un viso al usuario cuando se ha cerrado la sesión
  private closeSession(): void {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.timer = null;
    this.modal.warning({
      nzTitle: '<i>' + this.langService.translate(this.langService.ATENCION) + '</i>',
      nzContent: this.langService.translate(this.langService.VOLVER_INI_SESION),
      nzCentered: true,
      nzCancelDisabled: true,
      nzOkText: this.langService.translate(this.langService.ACEPTAR),
      nzOnOk: () => {
        this.logout();
      }, nzOnCancel: () => {
        this.logout();
      }
    });
  }

  // Cierra la sesión del usuario en la aplicación
  private logout(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.loginService.removeActiveUser();
    this.router.navigateByUrl('/');
  }

  onSelectEmpresa(empresa: EmpresaModel) {
    this.empresaSelect = empresa;
    this.accesos = [];
    this.usuario.Accesos.forEach(acceso => {
      if (acceso.Empresa.Id === this.empresaSelect.Id && !acceso.Virtual) {
        this.accesos.push(acceso);
      }
    });
  }

  selectTab(tabName: string): void {
    this.activeTab = tabName;
    switch (tabName) {
      case 'Apps':
        this.accesos = this.usuario.Accesos.filter(acceso => acceso.Empresa.Id === this.empresaSelect.Id && acceso.Aplicacion.NoWeb);
        break;
      case 'Ciudadano':
        this.accesos = this.usuario.Accesos.filter(acceso => acceso.Empresa.Id === this.empresaSelect.Id && acceso.Aplicacion.Ciudadano);
        break;
      default:
        this.accesos = this.usuario.Accesos.filter(acceso => acceso.Empresa.Id === this.empresaSelect.Id && !acceso.Aplicacion.NoWeb && !acceso.Aplicacion.Ciudadano);
        break;
    }
  }

  async onSelectAcceso(acceso: AccesoUsuarioModel) {
    if (acceso.UrlWeb.length > 0) {
      // Creo un ticket de acceso
      const ticketId = await this.loginService.newTicket(this.usuario.Id, acceso.Empresa.Id, acceso.Aplicacion.Id,
        acceso.Rol.Id, acceso.UrlApi, acceso.UrlApiIA, acceso.UrlApiEcoSAT);
      window.open(acceso.UrlWeb + '?ticketId=' + ticketId + '&origin=' + window.location.hostname);
    }
  }

  moodEntorno(): string {
    switch (this.entorno.url) {
      case 'https://dev.my.movisat.com/main':
        return this.entorno.value = 'Desarrollo'
      case 'https://preproduccion.my.movisat.com/main':
        return this.entorno.value = 'Preproducción'
      default:
        return this.entorno.value = 'Local'
    }
  }

  onUserClick() {
    // TODO: mostrar la información del usuario...
  }

  /**Fitro los datos de entrada de la lista para mostrar solo los datos deseados
  * @param {Event} event- Evento para capturar el valor de entrada del input
  * @return {EmpresaModel} Devuelvo la lista con las coincidencias
  */
  onInput(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    this.results = this.empresas.filter(empresas => {
      return empresas.Nombre.toLocaleLowerCase().includes(value.toLowerCase());
    })
  }

  click(event: Event) {
    if (event) {
      this.value = '';
      this.results = this.empresas
    }

  }

  // Cierra sesión y refresca la página
  onCloseSession() {
    const user = this.loginService.getActiveUser();
    if (user && user.Id) {
      this.loginService.invalidateUser(this.loginService.getActiveUser().Id);
      this.loginService.removeActiveUser();
    }
    this.router.navigateByUrl('/');
    setTimeout(() => {
      location.reload();
    }, 0);
  }

}


