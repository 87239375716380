import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzListModule } from 'ng-zorro-antd/list';
import { ListCardComponent } from './list-card/list-card.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';



@NgModule({
  declarations: [ListCardComponent],
  imports: [
    CommonModule,
    NzListModule,
    NzGridModule,
    NzLayoutModule

  ],
  exports: [
    ListCardComponent
  ]
})
export class ComponentsModule { }
