import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MainModule } from './main/main.module';
import { LoginModule } from './login/login.module';
import { ApiInterceptor } from './api/api.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';

import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule, NZ_ICONS } from 'ng-zorro-antd/icon';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'main', component: MainComponent }
];

// Importo los iconos que usa Ng-Zorro
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { es_ES, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ListCardComponent } from './components/list-card/list-card.component';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
}
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    MainModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NzFormModule,
    NzIconModule.forRoot(icons),
    NzModalModule,
    NzAlertModule,
    LoginModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: es_ES
    }, {
      provide: NZ_ICONS,
      useValue: icons
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
