import { AccesoUsuarioModel } from "./acceso.usuario.model";
import { BaseModel } from "./base.model";
import { IdiomaModel } from "./idioma.model";

export class UsuarioModel extends BaseModel {
    public Id: number;
    public Nombre: string;
    public Email: string;
    public Password: string;
    public Idioma: IdiomaModel;
    public Accesos: AccesoUsuarioModel[];
    public ValidadoFecha: Date;
    public DobleFactor: boolean;
    public FechaDF: Date;
    public FechaCambioPassword: Date;
    public FechaBloqueoTemp: Date;
    public MotivoBaja: number;
    public ControlPassword: boolean;
    public FechaCP: Date;
}
