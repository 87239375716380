import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private timer = null;
  public token = null;

  constructor(private http: HttpClient) { }

  public async getToken(): Promise<string> {
    try {
      this.token = null;
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const body = new URLSearchParams();
      body.set('grant_type', environment.grantType);
      body.set('client_id', environment.clientId);
      body.set('client_secret', environment.clientSecret);
      body.set('username', environment.userName);
      body.set('password', environment.password);
      // Pido el token y lo guardo
      const response = await this.http.post<string>(environment.urlApi + '/sso/token', body.toString(), httpOptions).toPromise();
      const data = JSON.parse(JSON.stringify(response));
      this.token = data.access_token;
      sessionStorage.setItem('tokenSSO', this.token);
      // Activo un temporizador para pedir un nuevo token un minuto antes que caduque
      if (data.expires_in >= 120) {
        this.startTimer(data.expires_in - 60);
      } else {
        this.startTimer(Math.round(data.expires_in / 2));
      }
    } catch {
      // Si falla la petición del token se pide uno cada dos segundos
      this.startTimer(2);
    }
    return this.token;
  }

  private startTimer(interval: number) {
    this.stopTimer();
    this.timer = setTimeout(() => {
      this.getToken();
    }, interval * 1000);
  }

  private stopTimer() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }

  // Permite saber si un usuario está validado
  public async isUsuarioValidado(userId: number): Promise<boolean> {
    try {
      await this.getToken();
      return await this.http.get<boolean>(environment.urlApi + '/sso/usuario/validado/' + userId).toPromise();
    } catch {
    }
    return false;
  }
}
