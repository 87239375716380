import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { IdiomaModel } from '../model/idioma.model';
import { TextoModel } from '../model/texto.model';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  public textos = new Map<string, string>();

  public EMPRESAS = 'EMPRESAS';
  public PROYECTOS = 'Proyectos';
  public APLICACIONES = 'APLICACIONES';
  public INFORMACION = 'Informacion';
  public CERRAR_SESION = 'Cerrar_sesion';
  public USU_PASSW_NO_VALIDOS = 'USU_PASSW_NO_VALIDOS';
  public USU_SIN_ACCESOS_APP = 'USU_SIN_ACCESOS_APP';
  public ATENCION = 'ATENCION';
  public REVISE_CORREO = 'REVISE_CORREO';
  public NO_CONEX_SERVIDOR = 'NO_CONEX_SERVIDOR';
  public ENTRAR = 'ENTRAR';
  public NO_RECUE_PASSW = 'NO_RECUE_PASSW';
  public RECU_PASSW_USU = 'RECU_PASSW_USU';
  public INTRO_USU_VALIDO = 'INTRO_USU_VALIDO';
  public INTRO_EMAIL_VALIDO = 'INTRO_EMAIL_VALIDO';
  public RECUPERACION = 'RECUPERACION';
  public PASSW_NO_COINCIDEN = 'PASSW_NO_COINCIDEN';
  public CODIGO = 'CODIGO';
  public ACEPTAR = 'ACEPTAR';
  public CANCELAR = 'CANCELAR';
  public PASSWORD = 'PASSWORD';
  public USUARIO = 'USUARIO';
  public NUEVO_PASSW = 'NUEVO_PASSW';
  public REPITA_PASSW = 'REPITA_PASSW';
  public FALLO_CAMBIO_PASSW = 'FALLO_CAMBIO_PASSW';
  public FALLO_CODIGO_PASSW = 'FALLO_CODIGO_PASSW';
  public FALLO_NOMBRE_USU = 'FALLO_NOMBRE_USU';
  public PASSW_ACTUALIZADO = 'PASSW_ACTUALIZADO';
  public VOLVER_INI_SESION = 'VOLVER_INI_SESION';
  public CREAR_USUARIO = 'CREAR_USUARIO';
  public NOMBRE_USUARIO = 'NOMBRE_USUARIO';
  public IDIOMA = "Idioma";
  public REVISE_CORREO_ACTIVAR = 'REVISE_CORREO_ACTIVAR';
  public FALLO_CREAR_USU = "FALLO_CREAR_USU";
  public USU_YA_EXISTE = "USU_YA_EXISTE";
  public PASSW_NO_VALIDO = "PASSW_NO_VALIDO";
  public SIMBOL_PASSW = '!#$%&()*+-:.;-_/<>?@[]';
  public CAMBIAR_PASSW_USU = 'CAMBIAR_PASSW_USU';
  public CAMBIO_PASSW = 'CAMBIO_PASSW';
  public MAS_5_MESES = 'MAS_5_MESES';
  public MAS_6_MESES = 'MAS_6_MESES';
  public PASSW_NO_MISMO = 'PASSW_NO_MISMO';
  public PRIMER_ACCESO = 'PRIMER_ACCESO';

  constructor(private http: HttpClient) { }

  translate(textId: string): string {
    let res = '';
    res = this.textos.get(textId);
    if (res === undefined || res === null) {
      res = '';
    }
    return res;
  }

  async getText(idiomaId: number): Promise<void> {
    try {
      const response = await this.http.get(environment.urlApi + '/sso/textos/' + idiomaId).toPromise();
      if (response !== null) {
        const res = JSON.parse(JSON.stringify(response));
        res.forEach(texto => {
          this.textos.set(texto.Id, texto.Texto);
        });
      }
    } catch {
    }
  }

  async getIdiomas(): Promise<IdiomaModel[]> {
    const result: IdiomaModel[] = [];
    try {
      const response = await this.http.get<IdiomaModel[]>(environment.urlApi + '/sso/idiomas').toPromise();
      if (response !== null) {
        return <IdiomaModel[]>JSON.parse(JSON.stringify(response));
      }
    } catch {
    }
    return null;
  }

}
